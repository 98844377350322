<template>
  <div ref="container" class="flex items-center gap-1 relative">
    <div class="iq-hover flex gap-1 items-center" @click="toggleMenu">
      <Icon
        :name="`flag:${locale === 'de' ? 'de' : 'gb'}-4x3`"
        class="w-5.5 h-3.5 iq-hover"
      />
      <span :class="props.classTxt ?? 'text-bold-12'">{{
        locale === 'de' ? 'DE' : 'EN'
      }}</span>
    </div>
    <div
      v-if="isShownMenu"
      ref="menu"
      class="absolute bg-iq-white rounded-sm shadow-lg flex flex-col -left-2 z-10"
      :class="props.openDirection === 'bottom' ? 'top-8' : 'bottom-8'"
    >
      <div
        v-for="(l, index) in locales"
        :key="l.code"
        class="flex items-center iq-hover gap-1 hover:bg-iq-grey-text px-2 py-1"
        :class="{
          'border-b border-b-iq-black': index < locales.length - 1,
        }"
        @click="update(l.code)"
      >
        <Icon
          :name="`flag:${l.code === 'de' ? 'de' : 'gb'}-4x3`"
          class="w-5 h-3"
        />
        <span
          :class="props.classTxt ?? 'text-bold-12'"
          class="!text-iq-black"
          >{{ l.code.toLocaleUpperCase() }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  classTxt?: string;
  openDirection?: 'bottom' | 'top';
}>();

const { locale, locales, setLocaleCookie, setLocale } = useI18n();
const container = ref<HTMLElement | null>(null);
const isShownMenu = ref(false);
const localeSelected = ref('');
const menu = ref<HTMLElement | null>(null);

function update(code: string) {
  setLocaleCookie(code);
  setLocale(code);
  isShownMenu.value = false;
  removeClickListener();
}

function toggleMenu() {
  isShownMenu.value = !isShownMenu.value;
  if (isShownMenu.value) {
    document.addEventListener('click', handleClickOutside);
  } else {
    removeClickListener();
  }
}

function handleClickOutside(event: Event) {
  if (
    menu.value &&
    !menu.value.contains(event.target as Node) &&
    container.value &&
    !container.value.contains(event.target as Node)
  ) {
    isShownMenu.value = false;
    removeClickListener();
  }
}

function removeClickListener() {
  document.removeEventListener('click', handleClickOutside);
}

onMounted(() => {
  localeSelected.value = locale.value;
});

onUnmounted(() => {
  removeClickListener();
});
</script>

<style scoped></style>
